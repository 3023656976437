#page-container {
  margin: 0 auto;
  width: 100%;
  min-width: 320px;
  background-color: #2c343f;
}
@media screen and (min-width: 992px) {
  #page-container.sidebar-l.sidebar-o {
    padding-left: 230px;
  }
  #page-container.sidebar-r.sidebar-o {
    padding-right: 230px;
  }
  #page-container.sidebar-l.sidebar-o.sidebar-mini {
    padding-left: 60px;
  }
  #page-container.sidebar-r.sidebar-o.sidebar-mini {
    padding-right: 60px;
  }
}
#sidebar,
#side-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1032;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: all 0.28s ease-out;
  transition: all 0.28s ease-out;
}
@media screen and (min-width: 992px) {
  .side-scroll #sidebar,
  .side-scroll #side-overlay {
    overflow-y: hidden;
  }
}
#sidebar {
  width: 230px;
  background-color: #2c343f;
}
.sidebar-l #sidebar {
  left: 0;
  -webkit-transform: translateX(-100%) translateY(0) translateZ(0);
  -ms-transform: translateX(-100%) translateY(0);
  transform: translateX(-100%) translateY(0) translateZ(0);
}
.sidebar-r #sidebar {
  right: 0;
  -webkit-transform: translateX(100%) translateY(0) translateZ(0);
  -ms-transform: translateX(100%) translateY(0);
  transform: translateX(100%) translateY(0) translateZ(0);
}
@media screen and (max-width: 991px) {
  #sidebar {
    width: 100%;
    opacity: 0;
  }
  .sidebar-o-xs #sidebar {
    opacity: 1;
    -webkit-transform: translateX(0) translateY(0) translateZ(0);
    -ms-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0) translateZ(0);
  }
}
@media screen and (min-width: 992px) {
  #sidebar {
    width: 230px;
    -webkit-transition: none;
    transition: none;
  }
  .sidebar-o #sidebar {
    -webkit-transform: translateX(0) translateY(0) translateZ(0);
    -ms-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0) translateZ(0);
  }
  .sidebar-o.sidebar-mini #sidebar {
    overflow-x: hidden;
    -webkit-transition: all 0.28s ease-out;
    transition: all 0.28s ease-out;
    will-change: transform;
  }
  .sidebar-l.sidebar-o.sidebar-mini #sidebar {
    -webkit-transform: translateX(-170px) translateY(0) translateZ(0);
    -ms-transform: translateX(-170px) translateY(0);
    transform: translateX(-170px) translateY(0) translateZ(0);
  }
  .sidebar-r.sidebar-o.sidebar-mini #sidebar {
    -webkit-transform: translateX(170px) translateY(0) translateZ(0);
    -ms-transform: translateX(170px) translateY(0);
    transform: translateX(170px) translateY(0) translateZ(0);
  }
  .sidebar-o.sidebar-mini #sidebar .sidebar-content {
    width: 230px;
    -webkit-transition: all 0.28s ease-out;
    transition: all 0.28s ease-out;
    will-change: transform;
  }
  .sidebar-l.sidebar-o.sidebar-mini #sidebar .sidebar-content {
    -webkit-transform: translateX(170px) translateY(0) translateZ(0);
    -ms-transform: translateX(170px) translateY(0);
    transform: translateX(170px) translateY(0) translateZ(0);
  }
  .sidebar-o.sidebar-mini #sidebar:hover,
  .sidebar-o.sidebar-mini #sidebar:hover .sidebar-content {
    -webkit-transform: translateX(0) translateY(0) translateZ(0);
    -ms-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0) translateZ(0);
  }
  .sidebar-o.sidebar-mini #sidebar .sidebar-mini-hide {
    opacity: 0;
    -webkit-transition: opacity 0.28s ease-out;
    transition: opacity 0.28s ease-out;
  }
  .sidebar-o.sidebar-mini #sidebar .sidebar-mini-hidden {
    display: none;
  }
  .sidebar-o.sidebar-mini #sidebar .nav-main > li.open > ul {
    display: none;
  }
  .sidebar-o.sidebar-mini #sidebar:hover .sidebar-mini-hide {
    opacity: 1;
  }
  .sidebar-o.sidebar-mini #sidebar:hover .nav-main > li.open > ul {
    display: block;
  }
}
#main-container,
#page-footer {
  overflow-x: hidden;
}
#main-container {
  background-color: #f5f5f5;
}
