.designs-list {
  flex-wrap: wrap;
  display: flex;
}

.design-cell {
  margin-bottom: 4px;
  margin-right: 35px;
  width: 200px;
  min-height: 250px;
  display: block;
  text-align: center;
  font-size: 12px;
}

.design-container {
  height: 160px;
  width: 100%;
  border: 1px solid #C6C6C6;
  border-radius: 3px;
  margin-bottom: 20px;
  overflow: hidden;
}

.design-cell img {
  width: 100%;
}

.design-cell h5 {
  text-align: center;
}
