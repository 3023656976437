.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: none;
  background: #fefefe;
}

.editor-readonly {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: none;
  background: #eaeaea;
  min-height: 140px;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

.draftJsMentionPlugin__mention__29BEd {
  background-color: #5c90d2;
  padding: 0.2em 0.6em 0.3em;
  padding-top: 0.2em;
  padding-right: 0.6em;
  padding-bottom: 0.3em;
  padding-left: 0.6em;
  font-size: 85%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.email-editor {
  border: 1px solid rgb(233, 233, 233);
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: inset 0px 1px 8px -3px #ababab;
}

.email-editor-header {
  background-color: #f5f5f5;
  padding: 10px 10px 10px 10px;
  height: 40px;
}

.email-editor-header ul {
  display: block;
  margin: 0 !important;
  padding: 0 !important;
}

.email-editor-header-btn {
  display: inline-block;
  border-radius: 100%;
  height: 12px !important;
  width: 12px !important;
  display: inline-block !important;
  margin-right: 10px !important;
}

.email-editor-header-btn-close {
  background-color: #fc615d;
}

.email-editor-header-btn-minimize {
  background-color: #fec041;
}

.email-editor-header-btn-expand {
  background-color: #34c84a;
}

.email-editor-subject {
  background-color: #fff;
  cursor: text;
  height: 40px;
  width: 100%;
  border-top: 1px solid rgb(233, 233, 233);
  border-bottom: 1px solid rgb(233, 233, 233);
  border-left: none;
  border-right: none;
  padding: 5px 20px 5px 20px;
}

.email-editor-subject::-moz-placeholder {
  color: #aaa;
}
.email-editor-subject:-ms-input-placeholder {
  color: #aaa;
}
.email-editor-subject::-webkit-input-placeholder {
  color: #aaa;
}

.email-editor-body {
  background-color: #f6f6f6;
}

.email-editor-body-content {
  background-color: #fff;
  cursor: text;
  margin: 20px;
  padding: 20px;
}

.email-editor-body-content p {
  margin-bottom: 0px;
}

.email-editor-body-club-footer {
  text-align: center;
  font-size: 12px;
  color: #999999;
}
