.label-container {
  position: fixed;
  bottom: 48px;
  left: 105px;
  display: table;
  visibility: hidden;
}

.label-text {
  color: #fff;
  background: rgba(51, 51, 51, 0.5);
  display: table-cell;
  vertical-align: middle;
  padding: 10px;
  border-radius: 3px;
}

.label-arrow {
  display: table-cell;
  vertical-align: middle;
  color: #333;
  opacity: 0.5;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  left: 20px;
  background-color: #44b4a6;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 1000;
  animation: bot-to-top 0.5s ease-out;
}

#FloatingAddButton ul {
  position: fixed;
  left: 40px;
  padding-bottom: 20px;
  bottom: 80px;
  z-index: 100;
}

#FloatingAddButton ul li {
  list-style: none;
  margin-bottom: 10px;
}

#FloatingAddButton i {
  color: #fff;
}

#FloatingAddButton ul li a {
  background-color: #44b4a6;
  color: #fff;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  width: 60px;
  height: 60px;
  display: block;
}

ul:hover {
  visibility: visible !important;
  opacity: 1 !important;
}

.my-float {
  font-size: 24px;
  margin-top: 18px;
}

a#FloatingAddButton:focus + ul {
  color: #fff;
}

a#FloatingAddButton i {
  animation: rotate-in 0.5s;
}

a#FloatingAddButton:hover > i {
  animation: rotate-out 0.5s;
}

@keyframes bot-to-top {
  0% {
    bottom: -40px;
  }
  50% {
    bottom: 40px;
  }
}

@keyframes scale-in {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes rotate-in {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate-out {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
