@font-face {
    font-family: 'Open Sans';
    src: url('opensans-light-webfont.woff2') format('woff2'),
         url('opensans-light-webfont.woff') format('woff'),
         url('./OpenSans-Light.ttf') format('truetype');
    font-weight: 300;
}
@font-face {
font-family: 'Open Sans';
    src: url('opensans-regular-webfont.woff2') format('woff2'),
         url('opensans-regular-webfont.woff') format('woff'),
         url('./OpenSans-Regular.ttf') format('truetype');
font-weight: 400;
font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('opensans-bold-webfont.woff2') format('woff2'),
         url('opensans-bold-webfont.woff') format('woff'),
         url('./OpenSans-Bold.ttf') format('truetype');
    font-weight: 700;
}
