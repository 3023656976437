.chevron {
    top: 0;
    height: 100%;
    width: 100%;
}

.conversion-container {
  position: relative;
  margin-top: 30px;
}

.chevron:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 51%;
    background-color: #CCDCF1;
    -webkit-transform: skew(0deg, 12deg);
    transform: skew(0deg, 12deg);
}

.chevron:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background-color: #CCDCF1;
    -webkit-transform: skew(0deg, -12deg);
    transform: skew(0deg, -12deg);
}

.conversion-container .conversion .chevron {
    display: inline-block;
    height: 60px;
    width: 60px;
    position: absolute;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    right: 2px;
}

.conversion-container .conversion .chevron:before {
    -webkit-transform: skew(0deg, 24deg);
    transform: skew(0deg, 24deg);
}

.conversion-container .conversion .chevron:after {
    -webkit-transform: skew(0deg, -24deg);
    transform: skew(0deg, -24deg);
}

.conversion-container .chevron {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    right: -2px;
}

.conversion-container .conversion .chevron .conversion-value {
    display: block;
    text-align: center;
    -webkit-transform: rotate(90deg);
    margin-top: 25px;
    transform: rotate(90deg);
    position: relative;
    z-index: 1;
}
