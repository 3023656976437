.sales-funnel > .sales-funnel-step {
  padding: 0;
  position: relative;
  margin-left: 30px;
  margin-right: -30px;
}

.sales-funnel > .sales-funnel-step > .sales-funnel-dot {
  position: absolute;
  width: 20px;
  height: 20px;
  display: block;
  background: #5c90d2;
  top: 17px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
}
.sales-funnel > .sales-funnel-step > .sales-funnel-label {
  position: absolute;
  height: 20px;
  padding-top: 5px;
  display: block;
  background-color: #5c90d2;
  top: 17px;
  margin-top: -15px;
  margin-left: -15px;
  z-index: 1;
}
.sales-funnel > .sales-funnel-step > .progress {
  left: -50%;
  position: relative;
  border-radius: 0px;
  height: 5px;
  box-shadow: none;
  margin: 10px 0;
}
.sales-funnel > .sales-funnel-step > .progress > .progress-bar {
  width: 0px;
  box-shadow: none;
  background: #98b9e3;
  transition: none;
}
.sales-funnel > .sales-funnel-step .bs-wizard-info {
  color: #999;
  font-size: 12px;
}
.sales-funnel > .sales-funnel-step.complete > .progress > .progress-bar {
  width: 100%;
}
.sales-funnel > .sales-funnel-step.active > .progress > .progress-bar {
  width: 50%;
}
.sales-funnel
  > .sales-funnel-step:first-child.active
  > .progress
  > .progress-bar {
  width: 0%;
}
.sales-funnel
  > .sales-funnel-step:last-child.active
  > .progress
  > .progress-bar {
  width: 100%;
}
.sales-funnel > .sales-funnel-step.disabled > .sales-funnel-dot {
  background-color: #f5f5f5;
}
.sales-funnel > .sales-funnel-step.disabled > .sales-funnel-dot:after {
  opacity: 0;
}
.sales-funnel > .sales-funnel-step.disabled > .sales-funnel-label {
  background-color: #f5f5f5;
  color: #999;
}
.sales-funnel > .sales-funnel-step.disabled > .sales-funnel-label:after {
  opacity: 0;
}
.sales-funnel > .sales-funnel-step:first-child > .progress {
  left: 0%;
  width: 50%;
}
.sales-funnel > .sales-funnel-step:last-child > .progress {
  width: 50%;
}
.sales-funnel > .sales-funnel-step.disabled span.sales-funnel-dot {
  pointer-events: none;
}
