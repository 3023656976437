/** Classes for the displayed toast **/
.Toastify__toast {
  border-radius: 8px;
  padding: 0.9rem;
}

.Toastify__toast--default {
  background-color: #fff;
}
.Toastify__toast--info {
  background-color: #fff;
}
.Toastify__toast--success {
  background-color: #fff;
}
.Toastify__toast--warning {
  background-color: #fff;
}
.Toastify__toast--error {
  background-color: #fff;
}
.Toastify__toast-body {
  font-family: 'Source Sans Pro', sans-serif;
  color: #323e48;
  font-weight: 600;
  font-size: 16px;
}

.Toastify__progress-bar--info {
  background-color: #3d7cc9;
}

.Toastify__progress-bar--success {
  background-color: rgba(0, 188, 112, 1);
}

.Toastify__progress-bar--warning {
  background-color: rgba(247, 172, 2, 1);
}

.Toastify__progress-bar--error {
  background-color: #ff7676;
}

.Toastify__close-button {
  color: #323e48;
}
