.invite-user-form {
  min-height: 373px;
}

.send-invite-success {
  padding-top: 90px;
}

.add-new-container {
  padding-right: 15px;
  padding-left: 15px;
}
.add-new {
  padding-top: 110px;
  margin-bottom: 30px;
  cursor: pointer;
  min-height: 373px;
  border-style: dashed;
  border-color: #babec1;
  border-width: 1px;
}
