.list-timeline-no-border {
  position: relative;
  padding-top: 10px;
}

.list-timeline-no-border > li {
  margin-bottom: 10px;
}

.list-timeline-no-border .list-timeline-no-border-time {
  margin: 0 -20px;
  padding: 10px 20px 10px 40px;
  min-height: 40px;
  text-align: right;
  color: #999;
  font-size: 13px;
  font-style: italic;
  background-color: #f9f9f9;
  border-radius: 2px;
}

.list-timeline-no-border .list-timeline-no-border-icon {
  position: absolute;
  top: 5px;
  left: 10px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
}

.list-timeline-no-border .list-timeline-no-border-content {
  padding: 10px 10px 1px;
}

.list-timeline-no-border .list-timeline-no-border-content > p:first-child {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .list-timeline-no-border {
    padding-top: 20px;
  }

  .list-timeline-no-border:before {
    position: absolute;
    top: 0;
    left: 15px;
    bottom: 0;
    display: block;
    width: 4px;
    content: "";
    background-color: #f9f9f9;
    z-index: 1;
  }

  .list-timeline-no-border > li {
    min-height: 40px;
    z-index: 2;
  }

  .list-timeline-no-border > li:last-child {
    margin-bottom: 0;
  }

  .list-timeline-no-border .list-timeline-no-border-time {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding-right: 0;
    padding-left: 0;
    width: 90px;
    background-color: transparent;
  }

  .list-timeline-no-border .list-timeline-no-border-icon {
    top: 3px;
    left: 0px;
    width: 34px;
    height: 34px;
    line-height: 34px;
    z-index: 2 !important;
  }

  .list-timeline-no-border .list-timeline-no-border-content {
    padding-left: 0px;
  }
}
