.notifications {
  width: 300px;
  left: -250px;
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.notification-header {
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.notification {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 12px;
  padding-right: 12px;
}
.notification .si {
  display: inline-block;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.notification p {
  margin-bottom: 2px !important;
}
.notification .notification-time {
  margin-left: 42px;
  color: #999999;
  font-size: 85%;
}

.notification:hover {
  background-color: #f9f9f9;
  color: #262626;
}

.notification-icon {
  float: left;
  top: 3px;
  left: 105px;
  width: 34px !important;
  height: 34px !important;
  line-height: 34px !important;
  z-index: 2 !important;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  margin-right: 7px;
}

.notification-tile {
  width: 280px;
  position: fixed;
  right: 40px;
  bottom: 0px;
  z-index: 100;
  margin-bottom: 20px !important;
  margin-right: 0px !important;
}

.notification-tile-enter {
  transform: translate(120%);
}

.notification-tile-enter.notification-tile-enter-active {
  transform: translate(0%);
  transition: transform 800ms;
}

.notification-tile-leave {
  transform: translate(0%);
}

.notification-tile-leave.notification-tile-leave-active {
  transform: translate(120%);
  transition: transform 600ms;
}
