body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.selectable {
  cursor: pointer;
}

.selected {
  border-color: #2c343f !important;
  background-color: #5c90d2 !important;
  color: #fff !important;
}

.size-to-fit {
  font-size: 80%;
}

.dropdown-menu > li > .DropDown {
  padding: 7px 12px;
  display: block;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.linkButton {
  background: none;
  border: none;
  color: #5c90d2;
}

.linkButton:hover,
.linkButton:focus {
  color: #3169b1;
  text-decoration: none;
}
.linkButton:hover.link-effect:before,
.linkButton:focus.link-effect:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

.sideNav {
  position: relative;
  display: block;
  padding: 10px 15px;
  font-weight: 600;
  border-radius: 3px;
}

.nav-pills > li.active > .sideNav,
.nav-pills > li.active > .sideNav:hover,
.nav-pills > li.active > .sideNav:focus {
  color: #fff;
  background-color: #5c90d2;
}

.topSubButton {
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #646464;
  font-weight: 600;
  border: 1px solid transparent;
  border-radius: 0;
}

.block > .nav-tabs.nav-tabs-alt > li.active > .topSubButton,
.block > .nav-tabs.nav-tabs-alt > li.active > .topSubButton:hover,
.block > .nav-tabs.nav-tabs-alt > li.active > .topSubButton:focus {
  box-shadow: 0 2px #5c90d2;
}

.w-30 {
  width: 30vw;
}
