.facebook-messages {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 450px;
  margin-top: -20px;
  padding-top: 20px;
}
.facebook-messages .facebook-message-container {
  width: 100%;
  display: inline-block;
}
.facebook-messages .facebook-message {
  word-wrap: break-word;
  margin-bottom: 10px;
  line-height: 24px;
}
.facebook-messages .facebook-message p {
  margin-bottom: 0px;
}

.facebook-message-timestamp {
  color: #999;
  font-size: 9px;
}

.clear {
  clear: both;
}

.from-me {
  float: right;
}
.from-me .facebook-message-timestamp {
  text-align: right;
}
.from-me .message-bubble {
  position: relative;
  padding: 10px 20px;
  color: white;
  background: #0b93f6;
  border-radius: 25px;
}
.from-me .message-bubble:before {
  content: "";
  position: absolute;
  z-index: 2;
  bottom: -2px;
  right: -7px;
  height: 20px;
  border-right: 20px solid #0b93f6;
  border-bottom-left-radius: 16px 14px;
  -webkit-transform: translate(0, -2px);
}
.from-me .message-bubble:after {
  content: "";
  position: absolute;
  z-index: 3;
  bottom: -2px;
  right: -56px;
  width: 26px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
  -webkit-transform: translate(-30px, -2px);
}

.from-them {
  float: left;
}
.from-them .message-bubble {
  position: relative;
  padding: 10px 20px;
  background: #e5e5ea;
  border-radius: 25px;
  color: black;
}
.from-them .message-bubble:before {
  content: "";
  position: absolute;
  z-index: 2;
  bottom: -2px;
  left: -7px;
  height: 20px;
  border-left: 20px solid #e5e5ea;
  border-bottom-right-radius: 16px 14px;
  -webkit-transform: translate(0, -2px);
}
.from-them .message-bubble:after {
  content: "";
  position: absolute;
  z-index: 3;
  bottom: -2px;
  left: 4px;
  width: 26px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
  -webkit-transform: translate(-30px, -2px);
}
