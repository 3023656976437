/* css elements to make the dropdown menu appear above
  input line and make it still appear presentable */

.select-up .Select-menu-outer {
  top: auto;
  bottom: 100%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border: 1px solid #ccc;
  border-bottom-color: #e6e6e6;
}

.select-up .Select-arrow {
  -ms-transform: rotate(180deg); /* IE 9 */
  -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
  transform: rotate(180deg);
  margin-bottom: 2px;
}
