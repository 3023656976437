#header-navbar {
  min-height: 60px;
  background-color: #fff;
}
#header-navbar:before,
#header-navbar:after {
  content: " ";
  display: table;
}
#header-navbar:after {
  clear: both;
}
#header-navbar button {
  color: rgba(255, 255, 255, 0.5);
}
#header-navbar button:hover,
#header-navbar button:focus {
  color: #fff;
}
.header-navbar-fixed #header-navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  min-width: 320px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.02);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.02);
}
.header-navbar-fixed #main-container {
  padding-top: 60px;
}
@media screen and (min-width: 992px) {
  .header-navbar-fixed.sidebar-l.sidebar-o #header-navbar {
    left: 230px;
  }
  .header-navbar-fixed.sidebar-r.sidebar-o #header-navbar {
    right: 230px;
  }
  .header-navbar-fixed.sidebar-l.sidebar-o.sidebar-mini #header-navbar {
    left: 60px;
  }
  .header-navbar-fixed.sidebar-r.sidebar-o.sidebar-mini #header-navbar {
    right: 60px;
  }
}
.header-navbar-transparent #header-navbar {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.header-navbar-transparent.header-navbar-fixed.header-navbar-scroll #header-navbar {
  background-color: #70b9eb;
}
.app-title {
  color: #000;
  font-size: 18px;
}
.app-title:hover {
  color: #000;
}
.nav-main {
  margin: 0 -20px;
  padding: 0;
  list-style: none;
}
.nav-main .nav-main-heading {
  padding: 22px 20px 6px 20px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.3);
}
.nav-main a {
  display: block;
  padding: 10px 20px;
  color: rgba(255, 255, 255, 0.5);
}
.nav-main a:hover,
.nav-main a:focus {
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.2);
}
.nav-main a:hover > i,
.nav-main a:focus > i {
  color: #fff;
}
.nav-main a.active,
.nav-main a.active:hover {
  color: #fff;
}
.nav-main a.active > i,
.nav-main a.active:hover > i {
  color: #fff;
}
.nav-main a > i {
  margin-right: 15px;
  color: rgba(255, 255, 255, 0.2);
}
.nav-main a.nav-submenu {
  position: relative;
  padding-right: 30px;
}
.nav-main a.nav-submenu:before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
  display: inline-block;
  font-family: 'FontAwesome';
  color: rgba(255, 255, 255, 0.25);
  content: "\f104";
}
.nav-main ul {
  margin: 0;
  padding: 0 0 0 50px;
  height: 0;
  list-style: none;
  background-color: rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.nav-main ul > li {
  opacity: 0;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -webkit-transform: translateX(-15px);
  -ms-transform: translateX(-15px);
  transform: translateX(-15px);
}
.nav-main ul .nav-main-heading {
  padding-left: 0;
  padding-right: 0;
  color: rgba(255, 255, 255, 0.65);
}
.nav-main ul a {
  padding: 8px 8px 8px 0;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.4);
}
.nav-main ul a:hover,
.nav-main ul a:focus {
  color: #fff;
  background-color: transparent;
}
.nav-main ul a > i {
  margin-right: 10px;
}
.nav-main ul ul {
  padding-left: 12px;
}
.nav-main li.open > a.nav-submenu {
  color: #fff;
}
.nav-main li.open > a.nav-submenu > i {
  color: #fff;
}
.nav-main li.open > a.nav-submenu:before {
  content: "\f107";
}
.nav-main li.open > ul {
  height: auto;
}
.nav-main li.open > ul > li {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.nav-main-header {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 20px;
  width: 100%;
  list-style: none;
  background-color: #2c343f;
  z-index: 1031;
  opacity: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: all 0.28s ease-out;
  transition: all 0.28s ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateX(0) translateY(-100%) translateZ(0);
  -ms-transform: translateX(0) translateY(-100%);
  transform: translateX(0) translateY(-100%) translateZ(0);
}
.nav-main-header.nav-main-header-o {
  -webkit-transform: translateX(0) translateY(0) translateZ(0);
  -ms-transform: translateX(0) translateY(0);
  transform: translateX(0) translateY(0) translateZ(0);
  opacity: 1;
}
.nav-main-header > li {
  margin: 0 0 10px;
}
.nav-main-header a {
  display: block;
  padding: 0 12px;
  min-height: 34px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 600;
  line-height: 34px;
}
.nav-main-header a:hover,
.nav-main-header a:focus,
.nav-main-header a.active {
  color: #fff;
}
.nav-main-header a.nav-submenu {
  position: relative;
  padding-right: 32px;
}
.nav-main-header a.nav-submenu:before {
  position: absolute;
  right: 10px;
  font-family: 'FontAwesome';
  content: "\f107";
}
.nav-main-header ul {
  margin: 0 0 0 15px;
  padding: 0;
  list-style: none;
  display: none;
}
.nav-main-header ul a {
  min-height: 32px;
  font-size: 13px;
  font-weight: 400;
  line-height: 32px;
}
.nav-main-header > li:hover > a.nav-submenu {
  color: #000;
}
.nav-main-header > li:hover > ul {
  display: block;
}
@media screen and (min-width: 768px) {
  .nav-main-header {
    position: static;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    padding: 0;
    width: auto;
    background-color: transparent;
    z-index: auto;
    opacity: 1;
    overflow-y: visible;
    -webkit-overflow-scrolling: auto;
    -webkit-transition: none;
    transition: none;
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    -webkit-transform: translateX(0) translateY(0) translateZ(0);
    -ms-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0) translateZ(0);
  }
  .nav-main-header > li {
    position: relative;
    margin: 0 10px 0 0;
    float: left;
  }
  .nav-main-header ul {
    position: absolute;
    left: 0;
    margin: 0;
    padding: 13px 0;
    min-width: 160px;
    background-color: #70b9eb;
  }
  .nav-main-header > li:last-child > ul {
    left: auto;
    right: 0;
  }
}
.nav-header {
  margin: 0;
  padding: 0;
  list-style: none;
}
.nav-header:before,
.nav-header:after {
  content: " ";
  display: table;
}
.nav-header:after {
  clear: both;
}
.nav-header > li {
  margin-right: 12px;
  float: left;
}
.nav-header > li > a,
.nav-header > li > .btn-group > a {
  padding: 0 12px;
  display: block;
  height: 34px;
  line-height: 34px;
  font-weight: 600;
}
.nav-header.pull-right > li {
  margin-right: 0;
  margin-left: 12px;
  float: left;
}
.nav-header .header-content {
  line-height: 34px;
}
.nav-header .header-search {
  width: 360px;
}
@media screen and (max-width: 767px) {
  .nav-header .header-search {
    display: none;
  }
  .nav-header .header-search.header-search-xs-visible {
    position: absolute;
    top: 60px;
    right: 0;
    left: 0;
    z-index: 999;
    display: block;
    width: 100%;
    border-top: 1px solid #f9f9f9;
  }
  .nav-header .header-search.header-search-xs-visible > form {
    padding: 14px 14px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.02);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.02);
  }
}
.nav-sub-header > li {
  float: none !important;
  margin: 0 0 7px !important;
}
.nav-sub-header > li > a {
  color: #70b9eb;
}
.nav-sub-header > li > a > i {
  opacity: .5;
}
@media screen and (min-width: 768px) {
  .nav-sub-header > li {
    float: left !important;
    margin: 0 7px 0 0 !important;
  }
}
@media (max-width: 767px) {
  .nav-sub-header .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .nav-sub-header .open .dropdown-menu .divider {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .nav-sub-header .open .dropdown-menu > li > a,
  .nav-sub-header .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px;
  }
  .nav-sub-header .open .dropdown-menu > li > a {
    line-height: 20px;
  }
  .nav-sub-header .open .dropdown-menu > li > a:hover,
  .nav-sub-header .dropdown-menu > li > a:focus {
    background-image: none;
  }
}
.nav-users {
  margin: 0;
  padding: 0;
  list-style: none;
}
.nav-users > li:last-child > a {
  border-bottom: none;
}
.nav-users a {
  position: relative;
  padding: 12px 8px 8px 71px;
  display: block;
  min-height: 62px;
  font-weight: 600;
  border-bottom: 1px solid #f3f3f3;
}
.nav-users a > img {
  position: absolute;
  left: 12px;
  top: 10px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
.nav-users a > i {
  position: absolute;
  left: 40px;
  top: 40px;
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
}
.nav-users a:hover {
  background-color: #f9f9f9;
}

.navbar-collapse {
  height: 0px;
  transition: height .5s ease;
  border-top: 0px;
  overflow: hidden;
}

.public-navbar-collapse.in {
  height: 145px;
}

.secure-navbar-collapse.in {
  height: 310px;
}
