.actionButton {
  margin-right: 4px;
}

.editLeadButton {
  margin-top: 12px;
  margin-bottom: 12px;
}

.label-outline {
  border-radius: 3px;
  border: 1px solid #e9e9e9;
  padding: 2px 10px 2px 10px;
  font-size: 14px;
}
