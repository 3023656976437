#hidden-toggle {
  cursor: pointer;
  top: -23px;
  right: 10px;
}

.colorSquare {
  width: 20px;
  height: 20px;
  border: solid 1px #666666;
  display: inline-block;
  margin-left: 5px;
  border-radius: 1.5px;
  vertical-align: middle;
}

.colorLabel {
  vertical-align: middle;
  padding-top: 10px;
}
